/**
 * Gerenciador do estado global das paginações, utilizando Vuex para manter e gerenciar os estados.
 *
 * @module paginationStore
 */
export const paginationStore = {
  namespaced: true,

  /**
   * Estado da store Vuex relacionado à paginação.
   *
   * @property {Object} state
   * @property {Object<string, Object>} state.paginations - Objeto que armazena os estados das paginações.
   */
  state: {
    /**
     * Objeto que contém os estados de múltiplas paginações.
     *
     * @type {Object<string, Object>}
     */
    paginations: {},
  },

  getters: {
    /**
     * Retorna o estado completo de todas as paginações.
     *
     * @param {Object} state - O estado da store Vuex.
     * @returns {Object<string, Object>} - Um objeto contendo os estados das paginações, onde a chave é o identificador único e o valor é o estado.
     */
    getPaginationState: (state) => state.paginations,
  },

  mutations: {
    /**
     * Utilizado para gerenciar o estado das paginações em diferentes páginas ou componentes
     *
     * @param {Object} state - O estado da store Vuex.
     * @param {Object} payload - Dados a serem usados para atualizar o estado da paginação.
     * @param {string} payload.paginationId - O identificador único da paginação a ser inicializada ou atualizada.
     * @param {Object} payload.data - Dados do estado da paginação (como perPage, currentPage, sortBy, etc.).
     */
    setPaginationState(state, { paginationId, data }) {
      // Atualiza o estado da paginação com os dados fornecidos
      state.paginations[paginationId] = data
    },
  },

  actions: {
    /**
     * Ação para inicializar o estado de uma paginação específica,
     * é chamado quando o componente é carregado ou quando o estado precisa ser configurado pela primeira vez.
     *
     * @param {Object} context - O contexto da store Vuex, fornecendo acesso às mutações e getters.
     * @param {Object} payload - Dados necessários para inicializar ou atualizar o estado.
     * @param {string} payload.paginationId - O identificador único da paginação a ser inicializada.
     * @param {Object} payload.data - Dados iniciais da paginação (como perPage, currentPage, sortBy, etc.).
     */
    initPagination({ commit }, { paginationId, data }) {
      // Chama a mutação para inicializar ou atualizar o estado da paginação
      commit('setPaginationState', { paginationId, data })
    },
  },
}

export default paginationStore
